<template>
  <div class="village-score">
    <step :stepArr="stepArr" :stepFull="false"></step>
    <div class="bottom-info">
      <div class="title">村情调研分数</div>
      <div class="score-info">
        <div class="topLabel">
          <div class="stationName">{{stationName}}</div>
          <div class="backBtn" @click="goback">返回修改</div>
        </div>
        <div class="flex">
          <div class="villageScore">{{villageScore}}</div>
          <div class="villageScore-commnet" >
            <div class="villageScore-commnet-info"> {{commnet}} </div>
          </div>
        </div>
        <EchartsView   :chartArr="chartArr"
                       :elID="'echert1'"
                       :showTitleArray="['人口','经济','居住','文化','综合']"
                       :show-max="4"
                       style="width: 100%;height: 240px;">
        </EchartsView>
      </div>
    </div>

    <div class="nextBtn">
      <div class="btn-active"
           @click="nextBtn">完 成</div>
    </div>
  </div>
</template>

<script>
import Step from '@/components/Step'
import { Rate } from 'vant';
import common from '@/utils/common';
import EchartsView from "../ECharts/EchartsRadar";
import {appAction, uwStatisticAction} from "@/utils/util";
import {PREVIOUS_PAGE, STATION_SCORE_NEXT} from "@/utils/constantNum";
import { getJoinInfoByStationNo } from '@/utils/api'
export default {
  components: {
    Step,
    [Rate.name]: Rate,
    EchartsView
  },
  data () {
    return {
      stepArr: [
        { name: "村情信息", isActive: true },
        { name: "站长信息", isActive: false }
      ],
      title: '吴房村',
      indicator: [
        { text: '人口', key:'population', max: 4 },
        { text: '经济', key:'economic', max: 4 },
        { text: '居住', key:'reside', max: 4 },
        { text: '文化', key:'culture', max: 4 },
        { text: '综合', key:'overrall', max: 4 }
      ],
      star: 3,
      commnet: '',
      chartArr: [],
      villageScore: '',
      score:{},
      stationName: ''
    }
  },
  mounted () {
    if (common.getItem('jumpFrom')  && (!this.$route.query.preIsH5)){
      this.chartArr.push(this.$route.query.population)
      this.chartArr.push(this.$route.query.economic)
      this.chartArr.push(this.$route.query.reside)
      this.chartArr.push(this.$route.query.culture)
      this.chartArr.push(this.$route.query.overrall)

      this.villageScore = Number(this.$route.query.villageScore)
      this.title = this.$route.query.stationName;
    } else if(this.$route.query.score) {
      this.score = JSON.parse(this.$route.query.score)
      this.chartArr.push(this.score.population)
      this.chartArr.push(this.score.economic)
      this.chartArr.push(this.score.reside)
      this.chartArr.push(this.score.culture)
      this.chartArr.push(this.score.overrall)
      this.villageScore = this.score.villageScore
      this.title = this.$route.query.stationName;
    }
    this.togetJoinInfoByStationNo()

      this.startShow(this.villageScore)
    uwStatisticAction('/site/villagescore','站点评分')
  },
  methods: {
    nextBtn() {

      if (common.getItem('jumpFrom') != undefined ){
          let p = {
            stationNo:this.$route.query.stationNo
          }
          appAction(STATION_SCORE_NEXT,p)
        }else{
          this.$router.push({
            path: '/newsiteList',
            query: {
              stationNo:this.$route.query.stationNo,
            }
          })
        }

      // if (common.getItem('jumpFrom')  && (!this.$route.query.preIsH5)){
      //   this.$router.push({
      //     path: '/newsiteList',
      //     query: {
      //       stationNo:this.$route.query.stationNo,
      //     }
      //   })

      // }else{
      //   this.$router.push({
      //     path: '/newsiteList',
      //     query: {
      //       stationNo:this.$route.query.stationNo,
      //     }
      //   })
      // }
      
      // else {
      //   let score =JSON.parse(this.$route.query.score)
      //   if (score && this.$route.query.idCard) {
      //     this.$router.push({
      //       path: '/nszz',
      //       query: {
      //         stationNo: score.stationNo,
      //         areaNo: score.areaNo,
      //         idCard: score.idCard,
      //         uniqueNo:this.$route.query.uniqueNo
      //       }
      //     })
      //   } else {
      //     this.$router.push({
      //       path: '/nszz',
      //       query: {
      //         stationNo: score.stationNo,
      //         areaNo: score.areaNo,
      //         uniqueNo:this.$route.query.uniqueNo
      //       }
      //     })
      //   }
      // }
    },
    togetJoinInfoByStationNo(){
      getJoinInfoByStationNo({
        stationNo: this.$route.query.stationNo
      }).then(res=>{
         if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.stationName = res.data.data.stationName
          }
        }
      })
    },
    startShow(score) {
      if(score > 100){
        this.star = 5
        this.commnet = '卓越'
      } else if(score > 90 ){
        this.star = 4
        this.commnet = '优质'
      } else if(score > 80 ){
        this.star = 3
        this.commnet = '良好'
      } else if(score >= 60 ){
        this.star = 2
        this.commnet = '达标'
      } else if(score < 60){
        this.star = 1
        this.commnet = '未达标'
      }
    },
    goback() {
      console.log("点击返回修改")
      if (common.getItem('jumpFrom') && (!this.$route.query.preIsH5)  ){
        console.log("App事件")
        appAction(PREVIOUS_PAGE)
      }else {
        console.log("h5路由返回")
        this.$router.go(-1)
      }

    },

  },
}
</script>

<style lang="less" scoped>
.village-score {
  background-color:#F7F8F9 ;
  min-height: 100%;
  font-family:PingFangSC-Regular,PingFang SC;
  .bottom-info{
    margin: 0 0.2rem;
    .title{
      height: 60px;
      font-size: 17px;
      color: #333333;
      font-weight: bold;
      line-height: 60px;
    }
    .score-info{
      height: 376px;background-color: white;border-radius: 5px;
      .topLabel{
        display: flex;justify-content: space-between;align-items: center;
        .stationName{
          padding-top: 20px;padding-left: 20px;font-size: 17px;font-weight: bold
        }
        .backBtn{
          padding-top: 20px;padding-right: 20px;color: #999999;font-size: 14px;
        }
      }
      .villageScore{
        padding-top: 10px;padding-left: 20px;color: #3F7C53;font-size: 45px;font-weight: bold
      }
      .villageScore-commnet{
        margin-top: 40px;padding-right: 20px;
        .villageScore-commnet-info{
          color: #3F7C53;background-color: #F1F7F4;;padding:0 5px;height: 20px;line-height: 20px;text-align: center;font-size: 11px;margin-left:10px
        }
      }
    }
  }
  .nextBtn {
  width: 100%;
  padding: 0.24rem;
  background: #f7f8f9;
  position: absolute;
  bottom: 0;
  left: 0;
}
.btn-active {
  height: 0.98rem;
  line-height: 0.98rem;
  text-align: center;
  background: #3f7c53;
  border-radius: 0.08rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  opacity: 1;
}
}
</style>
